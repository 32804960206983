<template>
  <footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
    <p class="text-muted text-center text-md-left">
      Copyright © 2021-{{ current_year }} wappin. All rights reserved
    </p>
    <p class="text-muted text-md-right">
      Version: {{ $store.state.ui.packageVersion }}
    </p>
  </footer>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Footer',
  data() {
    return {
      current_year: moment().format('YYYY'),
    };
  },
};
</script>
